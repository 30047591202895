import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
const svg = `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="337.578px" height="337.579px" viewBox="0 0 337.578 337.579" style="enable-background:new 0 0 337.578 337.579;"
xml:space="preserve">
<g>
<g>
 <g>
   <path d="M274.107,130.68c-14.646-26.151-43.487-41.154-74.424-36.215c-12.147-25.487-37.536-41.673-66.098-41.673
     c-40.421,0-73.318,32.897-73.318,73.334c0,1.666,0.066,3.386,0.216,5.17C25.593,139.254,0,170.75,0,207.025
     c0,42.88,34.879,77.756,77.753,77.756h57.604h3.849h14.382v-86.235l-10.863,10.863c-5.702,5.705-15.646,5.705-21.344,0
     c-2.846-2.847-4.428-6.642-4.428-10.671c0-4.035,1.576-7.812,4.435-10.658l36.635-36.653c2.798-2.801,6.689-4.407,10.661-4.407
     l1.465,0.048c3.54,0.285,6.836,1.802,9.395,4.341l36.659,36.671c2.852,2.847,4.425,6.63,4.425,10.665
     c0,4.022-1.573,7.83-4.432,10.676c-5.68,5.699-15.642,5.699-21.341,0l-11.085-11.103v86.469h76.057
     c42.875,0,77.751-34.882,77.751-77.756C337.578,169.717,310.515,137.471,274.107,130.68z"/>
 </g>
</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
`;
export default class InsertImage extends Plugin {
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add("insertImage", (locale) => {
      const view = new ButtonView(locale);
      view.set({
        label: "Insert image",
        icon: svg,
        tooltip: true,
      });
      // Callback executed once the image is clicked.
      const Id =  editor.config.get( 'editorId' );;
      const addElement = 'add-element'+ Id
      view.on("execute", () => {
        try {
        const attachEventName = 'custom-attachment';
        window.dispatchEvent(new CustomEvent(attachEventName, {detail : Id}));
        window.removeEventListener(addElement , ()=>{}) 
        } catch (error) {
          console.log('ERROR  ATTACH ====>' , error);
        }
      });
      window.addEventListener(addElement , (data)=> {
        if( data.detail && data.detail.type =='block'){
          editor.model.change((writer) => {
            const imageElement = writer.createElement(data.detail.blockName, data.detail.option);
            editor.model.insertContent(imageElement, editor.model.document.selection);
          });
        } else if(data.detail && data.detail.type =='html'){
          const viewFragment = editor.data.processor.toView( data.detail.html );
          const modelFragment = editor.data.toModel( viewFragment );
          editor.model.insertContent(modelFragment);
          }
          
        })
      return view;
    });
  }
}
