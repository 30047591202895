import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
const svg = `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="65px" height="65px" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve">
<path d="M20.1,37.2L20.1,37.2v-0.2c0.2-2.7,2.4-4.9,5.2-4.9H32c-1.1-1.9-0.9-4.4,0.7-6.2c0.1-0.1,0.1-0.1,0.2-0.2  l1.6-1.5c0.9-1,2.2-1.5,3.4-1.6V6.1c0-2.2-1.8-4-4-4H6.1c-2.2,0-4,1.8-4,4V28v6c0,2.2,1.8,4,4,4h6h7.9V37.2z M8.1,8.3  c0-0.6,0.4-1,1-1h6c0.6,0,1,0.4,1,1v2.8c0,0.6-0.4,1-1,1h-6c-0.6,0-1-0.4-1-1C8.1,11.1,8.1,8.3,8.1,8.3z M8.1,17c0-0.6,0.4-1,1-1H31  c0.6,0,1,0.4,1,1v2.8c0,0.6-0.4,1-1,1H9.2c-0.6,0-1-0.4-1-1L8.1,17L8.1,17z M8.1,28.6v-2.8c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v2.8  c0,0.6-0.4,1-1,1H9.2C8.5,29.6,8.1,29.2,8.1,28.6z"/>
<path d="M39.2,27L39.2,27c-0.6-0.5-1.3-0.5-1.7,0l-1.7,1.6l0,0c-0.4,0.5-0.4,1.2,0,1.6l4.4,4.4  c0.2,0.2,0.2,0.3,0.2,0.6c0,0.5-0.3,0.8-0.8,0.8H25.3c-0.6,0-1.1,0.5-1.2,1.1v2.4c0.1,0.6,0.6,1.1,1.2,1.3h14.3  c0.2,0,0.3,0.1,0.4,0.2c0.3,0.2,0.4,0.8,0.2,1.1l-4.4,4.4l0,0c-0.4,0.5-0.4,1.2,0,1.6l1.6,1.7l0,0c0.5,0.4,1.2,0.4,1.6,0l10.6-10.6  l0,0c0.4-0.5,0.4-1.2,0-1.6L39.2,27z"/>
</svg>`;
export default class OpenTemplate extends Plugin {
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add("openTemplate", (locale) => {
      const view = new ButtonView(locale);
      view.set({
        label: "عرض القوالب",
        icon: svg,
        tooltip: true,
      });
      view.on("execute", () => {        
        const editorId = editor.config.get( 'editorId' );
        window.dispatchEvent(new CustomEvent('onOpenTemplate' , {detail: editorId}));
      });
      return view;
    });
  }
}
