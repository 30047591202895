import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import Model from "@ckeditor/ckeditor5-ui/src/model";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";
import { addListToDropdown, createDropdown } from "@ckeditor/ckeditor5-ui/src/dropdown/utils";
import SplitButtonView from "@ckeditor/ckeditor5-ui/src/dropdown/button/splitbuttonview";
// import imageIcon from "../libraries/svg/user.svg";
export default class InsertDropDown extends Plugin {
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add("InsertDropDown", (locale) => {
      const dropdownView = createDropdown(locale, SplitButtonView);
      dropdownView.buttonView.actionView.set({
        withText: true,
        label: "إضافة نص",
        tooltip: true,
      });
      const items = new Collection();
      const editorId = editor.config.get( 'editorId' );
      window.addEventListener("loadCkKeywords", (data) => {
        if(data.detail && data.detail.editorId !== editorId ) return;
        if(items.clear) items.clear();
        const keywordsArr =
          data.detail && data.detail.data && data.detail.data.length > 0 ? data.detail.data : [];
        keywordsArr.forEach((el) => {
          items.add({
            type: "button",
            model: new Model({
              withText: true,
              label: el.text,
              value : el.value
            }),
          });
        });
      });
      addListToDropdown(dropdownView, items);
      dropdownView.on("execute", (eventInfo) => {
        const { value } = eventInfo.source;
        editor.model.change((writer) => {
          const viewFragment = editor.data.processor.toView(value );
          const modelFragment = editor.data.toModel( viewFragment );
          editor.model.insertContent(modelFragment);
        });
      });
      return dropdownView;
    });
  }
}
