export default class MyUploadAdapter {
  constructor(loader, editor) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this._editor = editor;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          if (this._editor && this._editor.config) {
            const editorId = this._editor.config.get("editorId");
            const uploadElement = "upload-element" + editorId;
            window.dispatchEvent(new CustomEvent("onStartUpload", { detail: { editorId, file } }));
            window.addEventListener(uploadElement, (data) => {
              if (data.detail.url && data.detail.editorId === editorId  ) {
                resolve({
                  default: data.detail.url,
                });
              }else{
                reject('Error upload File')
              }
            });
          }
        })
    );
  }

  abort() {}
}
export function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader, editor);
  };
}
