import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import Model from "@ckeditor/ckeditor5-ui/src/model";
import Collection from "@ckeditor/ckeditor5-utils/src/collection";
import { addListToDropdown, createDropdown } from "@ckeditor/ckeditor5-ui/src/dropdown/utils";
import SplitButtonView from "@ckeditor/ckeditor5-ui/src/dropdown/button/splitbuttonview";
// import imageIcon from "../libraries/svg/user.svg";
export default class TemplateDrop extends Plugin {
  init() {
    const editor = this.editor;
    editor.ui.componentFactory.add("TemplateDrop", (locale) => {
      const dropdownView = createDropdown(locale, SplitButtonView);
      dropdownView.buttonView.actionView.set({
        withText: true,
        label: "إختر من القوالب",
        tooltip: true,
        withText: true,
      });
      const defaultTemplate = [];
      window.addEventListener("loadTemplates", (data) => {
        const editorId = editor.config.get( 'editorId' );
        if(data.detail && data.detail.editorId !== editorId ) return;
        const items = new Collection();
        if(items.clear) items.clear()
        const keywordsArr =
          data.detail && data.detail.data && data.detail.data.length > 0 ? data.detail.data : defaultTemplate;
        keywordsArr.forEach((el) => {
          items.add({
            type: "button",
            model: new Model({
              withText: true,
              label: el.name,
              value : el.template
            }),
          });
        });
        addListToDropdown(dropdownView, items);
      });
      dropdownView.on("execute", (eventInfo) => {
        const { value } = eventInfo.source;
        editor.model.change((writer) => {
          const viewFragment = editor.data.processor.toView(value );
          const modelFragment = editor.data.toModel( viewFragment );
          editor.model.insertContent(modelFragment);
        });
      });
      return dropdownView;
    });
  }
}
